import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FacebookAccount } from '@core/models/interfaces/facebook/facebook-account';
import { AdAccountWithBusiness, IntegrationData, IntegrationItem } from '@core/models/interfaces/facebook/integration';
import { ResponseApi } from '@core/models/interfaces/common';

@Injectable({
  providedIn: 'root'
})
export class FacebookAdsAdminService {
  private baseUrl = `${environment.API_URL}/crm/facebook-ads`;

  constructor(private httpClient: HttpClient) {}

  authentication(accessToken: string) {
    return this.httpClient.post<FacebookAccount>(`${this.baseUrl}/authentication`, {
      accessToken
    });
  }

  getFacebookAccounts() {
    return this.httpClient.get<ResponseApi<FacebookAccount[]>>(`${this.baseUrl}/accounts`);
  }

  getIntegrationsData(accessToken: string) {
    return this.httpClient.get<IntegrationData>(`${this.baseUrl}/data`, {
      params: { accessToken }
    });
  }

  getBusinessPortfolio(accessToken: string) {
    return this.httpClient.get<ResponseApi<IntegrationItem[]>>(`${this.baseUrl}/business-portfolio`, {
      params: { accessToken }
    });
  }

  getAdAccountsWithBusiness(accessToken: string, limit?: number) {
    const queryParams = new HttpParams({
      fromObject: {
        accessToken,
        ...(limit && { limit: limit.toString() })
      }
    });
    return this.httpClient.get<ResponseApi<AdAccountWithBusiness[]>>(`${this.baseUrl}/ad-accounts`, {
      params: queryParams
    });
  }

  disconnectAccount(id: string) {
    return this.httpClient.delete(`${this.baseUrl}/disconnect-account/${id}`);
  }
}
